body{
  background-color: #212428 !important;
  font-family: Finlandica !important;

}
.title{
  color:#C4CFDE;
}
.navbar-brand {
  position: absolute;
  left: 50%;
  text-align: center;
  margin: 30px;
  font-size: 36px;
}